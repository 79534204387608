import React, { useEffect, useState } from 'react';
import { useRouter } from '@nintendo-of-america/next';
import {
  useLocalizer,
  deserializeHash,
} from '@nintendo-of-america/react-hooks';
import {
  DPadIcon,
  MegaphoneDarkIcon,
  SalesTagIcon,
  NsoIcon,
  PlusIcon,
  Heading,
  WrenchIcon,
} from '@nintendo-of-america/component-library';
import { Section, NewsArticles, PageHeader } from '@local/components';
import { getGraph, useQuery } from '@nintendo-of-america/graph-api';
import { queries } from '@local/lib/graph';
import { localizer, metaTitle } from '@local/i18n';
import { whatsNewLocaleAlternates } from '@local/lib/helpers/hreflang';
import { SYNDICATION } from '@nintendo-of-america/contentful-api/constants';
import { setFastlyHeaders } from '@local/lib/helpers';
import { useRestoreScroll } from '@local/lib/hooks';
import * as S from '@local/components/pages/WhatsNew/index.styles.js';

const categoryFilters = [
  {
    label: 'All',
    id: 'all',
    tags: [SYNDICATION.NCOM],
  },
  {
    label: 'Game News',
    id: 'game-news',
    icon: DPadIcon,
    tags: [SYNDICATION.NCOM, 'articleCategoryGameNews'],
  },
  {
    label: 'Events',
    id: 'events',
    icon: MegaphoneDarkIcon,
    tags: [SYNDICATION.NCOM, 'articleCategoryEvents'],
  },
  {
    label: 'Promotions',
    id: 'promotions',
    icon: SalesTagIcon,
    tags: [SYNDICATION.NCOM, 'articleCategoryPromotions'],
  },
  {
    label: 'Nintendo Switch Online',
    id: 'nintendo-switch-online',
    icon: NsoIcon,
    tags: [SYNDICATION.NCOM, 'syndicationNintendoSwitchOnline'],
  },
  {
    label: 'Ask The Developer',
    id: 'ask-the-developer',
    icon: WrenchIcon,
    tags: [SYNDICATION.NCOM, 'articleCategoryAskTheDeveloper'],
  },
];

const [defaultFilter] = categoryFilters;
const perPage = 30;

export async function getServerSideProps(context) {
  const { res, locale } = context;
  const graph = getGraph(context);
  // Result is cached and used during SSR
  await graph.query(queries.LatestNewsArticles, {
    locale,
    offset: 0,
    limit: perPage,
    tags: defaultFilter.tags,
  });

  setFastlyHeaders(res, { surrogateKeys: ['article', SYNDICATION.NCOM] });

  return graph.getServerSideProps({
    meta: {
      title: metaTitle('News', locale),
      description: localizer.text(
        'Your first stop for Nintendo news and announcements. See what’s new in the world of Nintendo, from site updates to game launches, deals, and more.',
        { locale }
      ),
    },
    analytics: {
      pageType: `News Article Listing`,
      pageName: "What's New",
      pageCategory: 'Merchandising',
    },
    localeAlternates: whatsNewLocaleAlternates,
  });
}

const WhatsNewPage = () => {
  const { text } = useLocalizer();
  const { locale, asPath } = useRouter();
  const [categoryFilter, setCategoryFilter] = useState(defaultFilter);
  const [offset, setOffset] = useState(0);
  const { data, loading } = useQuery(queries.LatestNewsArticles, {
    locale,
    offset,
    limit: perPage,
    tags: categoryFilter.tags,
  });

  const startFilter = deserializeHash(asPath)?.filter;
  const [articles, setArticles] = useState(data?.collection.items);
  const [total, setTotal] = useState(data?.collection.total);

  const { waitForElementRef } = useRestoreScroll();

  useEffect(() => {
    const items = data?.collection.items;
    if (items && data.collection.offset === offset) {
      setArticles((value) => (offset ? value.concat(items) : items));
      setTotal(data.collection.total);
    }
  }, [data?.collection, offset]);

  useEffect(() => {
    if (startFilter)
      setCategoryFilter(
        categoryFilters.find((filter) => filter.id === startFilter) ||
          defaultFilter
      );
  }, [startFilter]);

  return (
    <>
      <Section constrained small>
        <PageHeader>
          <S.PageIcon />
          <S.NewsHeading>{text('News & Events')}</S.NewsHeading>
        </PageHeader>
        <Heading.NewLevel>
          <S.PageFilters>
            <S.FilterHeading>{text('Filter Articles:')}</S.FilterHeading>
            {categoryFilters.map((filter) => (
              <S.FilterButton
                key={filter.label}
                icon={filter.icon}
                onClick={() => {
                  setOffset(0);
                  setCategoryFilter(filter);
                  window && (window.location.hash = '#filter=' + filter.id);
                }}
                variant="secondary"
                selected={filter?.id === categoryFilter?.id}
                still
              >
                {text(filter.label)}
              </S.FilterButton>
            ))}
          </S.PageFilters>
          <NewsArticles articles={articles} />
          {articles?.length < total && (
            <S.LoadMoreWrap>
              <S.LoadMoreButton
                icon={PlusIcon}
                variant="tertiary"
                size="large"
                isLoading={loading}
                onClick={() => setOffset((value) => value + perPage)}
              >
                {text('Load more')}
              </S.LoadMoreButton>
            </S.LoadMoreWrap>
          )}
          <span ref={waitForElementRef} />
        </Heading.NewLevel>
      </Section>
    </>
  );
};

export default WhatsNewPage;
