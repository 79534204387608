import styled from 'styled-components';
import {
  Button,
  Heading,
  NewsIcon,
} from '@nintendo-of-america/component-library';

export const FilterHeading = styled(Heading).attrs({
  variant: 'h3',
  as: 'span',
})`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.h3Mobile};
  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    font-size: ${({ theme }) => theme.font.size.h3Desktop};
  }
`;

export const FilterButton = styled(Button).attrs({
  round: true,
  size: 'small',
})``;

export const PageFilters = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing['8']};
  margin-bottom: ${({ theme }) => theme.spacing['24']};

  ${FilterHeading} {
    margin: 0;
  }

  ${({ theme }) => theme.mediaQuery.tablet} {
    gap: ${({ theme }) => theme.spacing['16']};
  }
`;

export const NewsHeading = styled(Heading).attrs({
  variant: 'h1',
  large: true,
})`
  margin-right: auto;
`;

export const PageIcon = styled(NewsIcon)`
  margin-right: ${({ theme }) => theme.spacing['16']};
  width: ${({ theme }) => theme.spacing['32']};

  ${({ theme }) => theme.mediaQuery.desktop} {
    width: ${({ theme }) => theme.spacing['40']};
  }
`;

export const LoadMoreWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing[32]};
`;

export const LoadMoreButton = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.tablet} {
    width: auto;
  }
`;
